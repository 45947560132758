var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    service: true,
    'no-image-no-desc':
      _vm.service.restaurant.photoLink == undefined &&
      !_vm.service.restaurant.names.translation[_vm.language].description,
  },on:{"click":_vm.goToMenu}},[_c('h4',{class:{
        'service-title': true,
        'no-image-title-height': _vm.service.restaurant.photoLink == undefined,
      }},[_vm._v(_vm._s(_vm.serviceName))]),_c('div',{attrs:{"id":"image-and-description"}},[(_vm.service.restaurant.photoLink != undefined)?_c('img',{class:{
      'service-image': true,
      'no-description-image-height': !_vm.service.restaurant.names.translation[_vm.language].description,
    },attrs:{"src":("" + (_vm.service.restaurant.photoLink)),"alt":""}}):_vm._e(),_c('div',{staticClass:"info-container"},[(_vm.service.restaurant.names.translation[_vm.language].description)?_c('v-clamp',{class:{ 'service-description': true, 'no-image-description-margin-left': _vm.service.restaurant.photoLink == undefined },attrs:{"expanded":_vm.isExpanded,"max-lines":4},on:{"clampchange":_vm.onClampChange}},[_vm._v(_vm._s(_vm.service.restaurant.names.translation[_vm.language].description))]):_vm._e(),(_vm.clamped)?_c('h6',{class:{ 'no-image-description-margin-left': _vm.service.restaurant.photoLink == undefined },attrs:{"id":"show-more"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleExpansion.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.isExpanded ? _vm.labels.cart.readLess[_vm.language] : _vm.labels.cart.readMore[_vm.language])+" ")]):_vm._e()],1)]),(_vm.service.restaurant.startTime || _vm.service.restaurant.endTime)?_c('h4',{staticClass:"hours"},[(_vm.service.restaurant.startTime)?_c('span',[_vm._v(" "+_vm._s(_vm.startTimeNoSeconds)+" ")]):_vm._e(),_vm._v(" - "),(_vm.service.restaurant.endTime)?_c('span',[_vm._v(_vm._s(_vm.endTimeNoSeconds))]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }